@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing:border-box;
  padding:  0;
  margin: 0;
}

/* opacity */
.hover-opacity {
  transition: all .2s ease;
}
.hover-opacity:hover {
  opacity:0.5;
}

/* scale */
.hover-scale:hover {
  scale : .975;
}
.hover-scale {
  transition: all .2s ease;
}

/* border */
.hover-border {
  border-width: .4px;
  border-style: solid;
  border-color: transparent;
  transition: all .2s ease;
}
.hover-border:hover {
  border-color: #999;
}

.hover-border:active {
  border-color: #333;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}






.ReactModal__Content {
  scale: .75;
  transition: all 200ms ease;
}

.ReactModal__Content--after-open {
  scale: 1;
}

.ReactModal__Content--before-close {
  scale: .75;
}


.ReactModal__Overlay {
  opacity: 0;
  z-index: 20000;
  transition: all 200ms ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.pac-container {
  z-index: 30000 !important;
}
